import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './ToastStyle.less';

/** Toast props */
interface IProps {
	content?: string;
	isOpen: boolean;
	type?: string;
	bigFont?: boolean;
}

/**
 * index page
 *
 * @class Toast
 * @extends {React.Component<IProps,any>}
 */
class Toast extends PureComponent<IProps, any> {
	public static defaultProps: { isOpen: boolean; content: string };

	constructor(props: IProps) {
		super(props);
	}

	/**
	 * render
	 *
	 * @returns {*}
	 * @memberof Toast
	 */
	public render(): any {
		const { content, isOpen, type, bigFont } = this.props;
		console.log(bigFont);

		return (
			<div className={`toast ${isOpen ? '' : 'closed'}`}>
				<div className={`bd${type ? ' big' : ''}`}>
					<div className={type}></div>
					<p className={`cnt${bigFont ? ' bigfont' : ''}`}>{content}</p>
				</div>
			</div>
		);
	}
}

Toast.defaultProps = {
	isOpen: false,
	content: '',
};

export default Toast;
