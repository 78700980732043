import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper/core';
SwiperCore.use([Pagination, Autoplay]);

import './index.less';

export default class ThirdScreen extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            certificateList: [
                [
                    'https://img.qlchat.com/qlLive/activity/image/GOZD5H91-PLSY-H2EK-1709802147639-H8YJJQJVGYSV.png',
                    'https://img.qlchat.com/qlLive/activity/image/8RQHJXCF-YLO6-KBIG-1709802151613-R3AD4T4TUW7T.png',
                    'https://img.qlchat.com/qlLive/activity/image/VJM829DJ-CVMR-7OJ6-1709802155526-MVIPEB46GBG7.png',
                    'https://img.qlchat.com/qlLive/activity/image/B44ZCMCQ-XQR1-XHNS-1709802159924-43EVY4CZMS7I.png',
                ],
                [
                    'https://img.qlchat.com/qlLive/activity/image/XOZ4EC57-URCC-6ZQH-1709802163197-37AVLS3KBF9K.png',
                    'https://img.qlchat.com/qlLive/activity/image/VLRW9OR1-WF92-BTXH-1709802166350-YOWHG4LO9GSW.png',
                    'https://img.qlchat.com/qlLive/activity/image/GCBJ32X7-EE1C-VL5O-1709802171198-S19B7HQGSBOE.png',
                    'https://img.qlchat.com/qlLive/activity/image/9YH94DFC-ZQ92-8RTY-1709802174215-9OYNYIR7D777.png',
                ],
                [
                    'https://img.qlchat.com/qlLive/activity/image/7EUBC2OF-55K4-DUW4-1709802177295-XKP3PUB8ODQ9.png',
                    'https://img.qlchat.com/qlLive/activity/image/6ZII31JB-U3KK-USBB-1709802180205-1LMLCXARBSNP.png',
                    'https://img.qlchat.com/qlLive/activity/image/OAWFGL69-TZJI-FSSC-1709802183577-C5QZGMCQHJXH.png',
                    'https://img.qlchat.com/qlLive/activity/image/O86QZORF-NBB8-12SR-1709802186556-TFCKRFBVIM7N.png',
                ],
                [
                    'https://img.qlchat.com/qlLive/activity/image/MPX1DGRZ-AXGS-HKVH-1709802189381-NHLV6LB7GG7W.png',
                    'https://img.qlchat.com/qlLive/activity/image/ITWIVB44-ADSA-IZVQ-1709802192172-43QNDJIN3ICJ.png',
                    'https://img.qlchat.com/qlLive/activity/image/29GKH9XY-12M7-LBVP-1709802194879-Z5PGM24EJFXZ.png',
                    'https://img.qlchat.com/qlLive/activity/image/5GHK5GMM-P6PJ-8VPZ-1709802197669-2TWSKBJL3X9B.png',
                ]
            ]
        }
    }
    public render(): any {
        const { certificateList } = this.state;
        const certificateListFlag: boolean = certificateList.length > 1;
        return (
            <div className="thirds-screen">
                <Swiper
                    observer={true}
                    observeParents={true}
                    loop={certificateListFlag}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    slidesPerView={'auto'}
                    centeredSlides={true}
                    // pagination={{ clickable: true }}
                    className="famous-certificate-box-swiper">
                    {certificateList.map((item: any, index: number): any => (
                        <SwiperSlide key={index}>
                            <div className='certificate-card'>
                                {
                                    item.map((imgItem: any, imgIndex: number) => (
                                        <img key={imgIndex} className="famous-certificate-box-img" src={imgItem} />
                                    ))
                                }
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        )
    }
}