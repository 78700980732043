import React from 'react';
import { autobind } from 'core-decorators';
import { getHtmlTDK, ishaizhan } from '../../../lib/utils/UtilsCommon';
import './Home.less';
import 'react-id-swiper/src/styles/css/swiper.css';
import Page from '../../../components/page/PageIndex';
import TopMenu from '../components/TopMenu/index';
import HomeTrainingTopBanner from './components/homeTrainingTopBanner';
import CategoryList from './components/categoryList';
import ProductAdvantages from './components/productAdvantages';
import FamousTeacher from './components/famousTeacher';
import { post } from '../../../lib/utils/Request';
import CommunityList from './components/communityList';
import HomeAbout from './components/homeAbout';
import StudentStory from './components/studentStory';
import NewsInformation from './components/newsInformation';
import PageFooter from '../components/pageFooter';
import LefNav from './components/leftNav';
import SecondScreen from './components/secondScreen';
import ThirdScreen from  './components/thirdScreen';
import FourthScreen from './components/fourthScreen';

/**
 * state
 */
interface IState {
	htmlTDK: {
		title: string;
	};
}

/**
 * HomeIndex page
 *
 * @class HomeIndex
 * @extends {React.Component<any>}
 */
@autobind
class HomeIndex extends React.Component<any, any> {
	constructor(props: any, context?: any) {
		super(props, context);
		/**
		 * 静态化渲染数据放这里处理
		 */

		const state: any = {
			htmlTDK: {
				title: '学兴趣就上兴趣岛——名师更多，课程更全',
				pageDescription: '兴趣岛兴趣学堂-名师更多，课程更全。摄影、瑜伽等30门主流课程21门学员数量全国领先。',
				pageKeyword: '兴趣,兴趣岛,兴趣岛兴趣学堂,兴趣学堂,千聊兴趣岛,兴趣岛-名师更多，课程更全,兴趣岛兴趣学堂-名师更多，课程更全,千聊,知识付费,内容付费,千聊特训营,成人教育',
			},
			loading: true,
		};

		this.state = state;
	}

	/**
	 * 动态渲染数据放这里处理
	 *
	 * @static
	 * @param {*} { req }
	 * @returns {Promise<any>}
	 * @memberof HomeTraining
	 */
	public static async getInitialProps(context: any): Promise<any> {
		const { query, params } = context.req;

		let initData: any = {
			...params,
		};
		
		const res:any = await	post({ url:'/article/listArticleForOffice', data: { page: 1, size: 5 }}, context.req)
		initData.communityList = res.data
	
		return {
			query,
			initData,
			sysTime: Date.now(),
		};
	}

	/**
	 * 客户端调用获取数据
	 *
	 * @memberof HomeTraining
	 */
	public async componentDidMount(): Promise<any> {
		// 注册接口
		setTimeout(async () => {
			this.setState({ loading: false }, () => {
				this.initWowScript();
			});
		}, 200)
		return {};
	}
	/**
	 * 创建
	 */
	public createScript(src: string): any {
		return new Promise((resolve, reject): any => {
			const script: any = document.createElement('script');
			script.src = src;
			script.onload = (): any => {
				resolve(true);
			};
			document.body.appendChild(script);
		});
	}
	/**
	 * 初始化
	 */
	public async initWowScript(): Promise<any> {
		await this.createScript('https://media.qlchat.com/qlLive/activity/file/CCLYDTEJ-OKR7-EAGL-1658830848309-6B1TW5NUG22Y.js');
		if (!/msie [6|7|8|9]/i.test(navigator.userAgent)) {
			return await new (window as any).WOW({ scrollContainer: '.home-container-mobile' }).init();
		}
	}

	/**
	 * 渲染页面
	 *
	 * @returns {*}
	 * @memberof HomeIndex
	 */
	public render(): any {
		const { htmlTDK, loading } = this.state;
		const { initData } = this.props;
		const host = ishaizhan();

		if (loading) return null;
		return (
			<Page {...getHtmlTDK(htmlTDK)} bodyClass="home-container-mobile">
				<LefNav />
				<TopMenu activeTab="home" parentName=".home-container-mobile" />
				<HomeTrainingTopBanner />
				{/* 顶部banner */}
				<div className="main-container">
					<div className="main-container-bg second-bg fadeInUp">
						<div className="home-training-new-title">
							<img src="https://media.qianliaowang.com/qlLive/activity/image/JDTNUIR4-TSJC-IJUO-1728978248769-8HBMKBWLV47X.png" />
						</div>
						<div className="home-training-content">
							<p>{host ? '广州知识天下信息科技有限公司——' : ''}兴趣岛是一家成立于2016年的文化服务科技公司，为用户提供多种兴趣学习产品。截至目前，兴趣岛App注册用户3500万+。</p>
							<p>艾媒认证，兴趣岛摄影、瑜伽、太极、养生等30门主流课程内的21门课程学员数量全国领先。</p>
						</div>
						<SecondScreen />
					</div>
					<div className="main-container-bg third-bg wow fadeInUp">
						<div className="home-training-new-title">
							<img src="https://img.qlchat.com/qlLive/activity/image/2EN2964A-XV77-AGG7-1709177083968-I27424D3137P.png" />
						</div>
						<div className="home-training-content">
							<p>2016年至今，兴趣岛始终坚持用户第一，获得千万用户的肯定与好评，在课程学员数量上领先行业。</p>
							<p>兴趣岛引领行业发展，受到中国家庭教育学会、中国营养协会等权威单位认可，并屡获腾讯、百度、360等平台奖项。</p>
						</div>
						<ThirdScreen />
					</div>
					<div className="main-container-bg fourth-bg wow fadeInUp">
						<div className="home-training-new-title">
							<img src="https://img.qlchat.com/qlLive/activity/image/29F4BN89-YKMQ-F6NS-1709797222078-9D59GIMDYHNE.png" />
						</div>
						<FourthScreen />
					</div>
					{/* <div className="main-container-bg bg1">
						<div className="home-training-title">
							<img src="https://img.qlchat.com/qlLive/activity/image/9OMRUC1J-TDIZ-22O1-1658821472255-1CH6J7Q3WIUZ.png?x-oss-process=image/format,webp" />
						</div>
						<CategoryList className="main-container-width " />
					</div>
					<div className="main-container-bg bg2">
						<div className="home-training-title big">
							<img src="https://img.qlchat.com/qlLive/activity/image/SFEJTKX3-CGN2-FG24-1629984150549-DHOU1BC8U23H.png?x-oss-process=image/format,webp" />
						</div>
						<ProductAdvantages className="main-container-width" />
					</div>
					<div className="main-container-bg bg2">
						<div className="home-training-title big wow fadeInUp">
							<img src="https://img.qlchat.com/qlLive/activity/image/54SZLKNZ-HXVH-R2K2-1652260036267-YIOKD395FEVO.png?x-oss-process=image/format,webp" />
						</div>
						<FamousTeacher className="main-container-width wow fadeInUp" />
					</div>
					<div className="main-container-bg bg3">
						<div className="home-training-title big wow fadeInUp">
							<img src="https://img.qlchat.com/qlLive/activity/image/NOFBYMCR-DRE6-GWUZ-1653561426340-QGYQOKPOHB6M.png?x-oss-process=image/format,webp" />
						</div>
						<CommunityList dataList={initData.communityList} className="main-container-width wow fadeInUp" />
					</div>
					<div className="home-training-grey-bg-about">
						<img className="about-bg wow fadeInUp" src="https://img.qlchat.com/qlLive/activity/image/BB2CF6XT-6H44-ZWCI-1687334027163-J4Q378SPGDCS.png?x-oss-process=image/format,webp" />
						<div className="home-training-title wow fadeInUp">
							<img src="https://img.qlchat.com/qlLive/activity/image/FDEVWVT5-PYSK-EA5W-1659667483035-98WTFJFAZO6I.png?x-oss-process=image/format,webp" />
						</div>
						<HomeAbout className="main-container-width about-contain wow fadeInUp" />
					</div>
					<div className="home-training-grey-bg">
						<div className="home-training-title wow fadeInUp">
							<img src="https://img.qlchat.com/qlLive/activity/image/A65VB7B8-DU6P-6727-1659670300260-F9VIOL6Q25S5.png?x-oss-process=image/format,webp" />
						</div>
						<StudentStory className="main-container-width wow fadeInUp" />
					</div> */}
					{/* <div className="home-training-grey-bg">
						<div className="home-training-title wow fadeInUp">
							<img src="https://img.qlchat.com/qlLive/activity/image/KUWMGX3D-Y29G-M28B-1659686946984-8YXOMRWLB634.png?x-oss-process=image/format,webp" />
						</div>
						<NewsInformation className="main-container-width wow fadeInUp" />
					</div> */}
					<PageFooter />
				</div>
			</Page>
		);
	}
}

export default HomeIndex;
