import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ishaizhan } from '../../../../../lib/utils/UtilsCommon';
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Autoplay]);
import 'swiper/swiper.less';

import './index.less';

export default class FourthScreen extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            newsList: [
                {
					headerImg:'https://media.qianliaowang.com/qlLive/activity/image/DL9LWEBE-WNBD-16DV-1743676374320-U8UCR3AIZRO5.png?x-oss-process=image/format,webp',
					title:'2025兴趣岛艺术节启幕，深耕银龄文化服务探索"第二人生"无限精彩',
					desc:'3月28日，央级媒体中国日报网发布报道，讲述2025兴趣岛艺术节现场盛况，聚焦兴趣岛名师更多，指出兴趣岛组建名师团队，通过高质量的专业教学，帮助银龄群体在多个艺术兴趣领域突破自我。',
					link:'https://cnews.chinadaily.com.cn/a/202503/28/WS67e6384aa31008317a2af1b4.html',
				},
				{
					headerImg:'https://img.qlchat.com/qlLive/activity/image/Q63NWZUR-966J-OBYX-1709002656366-QMTFNWE295TM.png?x-oss-process=image/format,webp',
					title:'广州上百人登山练太极，“文化+游学”受关注',
					desc:'9月19日，央级媒体新华网发布报道，讲述兴趣岛武当太极游学活动盛况，及其对响应国家号召弘扬中国传统文化的意义。',
					link:'https://app.xinhuanet.com/news/article.html?articleId=2f0eb7588363f1bdc0175cbdd4340640&timestamp=88759',
				},
				{
					headerImg:'https://media.qianliaowang.com/qlLive/activity/image/XQWC8974-7AA9-BGOM-1731046815600-CV77OWLLA2RM.png?x-oss-process=image/format,webp',
					title:'兴趣岛亮相2024广州文交会，共同构建多领域创新发展新格局',
					desc:'9月27日-29日，2024广州文化产业交易会在广交会展馆举办。期间，兴趣岛展出企业如何通过中华优秀传统文化激发银发一族的兴趣文化空间，并荣获2024年“广州文化企业30强”称号。',
					link:'http://news.china.com.cn/mts/2024-10/08/content_1195793.htm',
				},
				{
					headerImg:'https://img.qlchat.com/qlLive/activity/image/CZPAOS44-3BYB-74AJ-1709002652926-5WP3A2OHX94Q.png?x-oss-process=image/format,webp',
					title:'公益旅拍热度攀升，这场手机摄影大赛初赛百强“出圈”',
					desc:'今年9月，2024兴趣岛第三届手机摄影大赛公布初赛赛果，历经为期两个多月紧张的征稿、评审环节，600名选手在初赛中脱颖而出，获6个赛道“百强奖”，并在线分享手机摄影学习心得和作品立意。',
					link:'https://cn.chinadaily.com.cn/a/202409/19/WS66ebdc08a310a792b3abceb8.html',
				},
				{
					headerImg:'https://media.qianliaowang.com/qlLive/activity/image/FP6VEO4D-ZVAW-TIP8-1731044852473-QQ9DOL5QHH4Z.png?x-oss-process=image/format,webp',
					title:'兴趣岛受邀参加2024中国数字文娱大会',
					desc:'今年8月，2024年中国数字文娱大会在广州市天河区开幕，'+ (ishaizhan() ? '广州知识天下信息科技有限公司' : '广州兴趣岛信息科技有限公司') +'（以下简称兴趣岛）受邀参加，体现了文娱行业和政府单位对兴趣岛创新实力和行业影响力的认可。',
					link:'https://app.ceweekly.cn/h5/#/pages/detail/articleDetail/articleDetail?id=453025&share=1',
				},
				{
					headerImg:'https://media.qianliaowang.com/qlLive/activity/image/4731W64J-5PRQ-IAWU-1731044854422-NBBA1F1Y211S.png?x-oss-process=image/format,webp',
					title:'兴趣岛终南山武当太极活动：边游边学，让更多人感受太极之美',
					desc:'10月18日，兴趣岛武当太极终南山站“强身心溯文化共作武当太极传承人”活动今年已举办第三次，本次活动带领近百位银龄人群感受武当太极非遗之美，被国家文旅部直属媒体《文旅中国》深度报道并高度评价本次活动让传统武术有了新的“打开方式”。',
					link:'https://share.ccmapp.cn/shareDetail?1=1&action=opendetail%3Brichtext%3B671e0bdc56be8b58b018b24e&siteId=2',
				},
				{
					headerImg:'https://media.qianliaowang.com/qlLive/activity/image/MLOPCT67-GBK4-NWUO-1731044850458-CIQ5JIPXS6PS.png?x-oss-process=image/format,webp',
					title:'东方智慧与现代生活深度融合，兴趣岛武当太极蔚然成风',
					desc:'10月30日，中宣部新华社《半月谈》发长文深度报道兴趣岛武当太极是东方智慧与现代生活深度融合，据悉，《半月谈》被誉为"中华第一刊"，是是中宣部委托新华社主办的重要党刊，本报道对于兴趣岛媒体传播极具影响力。',
					link:'https://mp.weixin.qq.com/s/Dc6zZ_Nma7hhxnLwMv65DQ',
				},
            ],
        }
    }

    public render(): any {
        const { newsList } = this.state;
        const bannersFlag: boolean = newsList.length > 1;
        return (
            <div className="fourth-screen">
                <Swiper
                    observer={true}
                    observeParents={true}
                    loop={bannersFlag}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    slidesPerView={'auto'}
                    centeredSlides={true}
                    // pagination={{ clickable: true }}
                    className="news-information-box-swiper">
                    {newsList.map((item: any, index: number): any => (
                        <SwiperSlide key={index} className="news-information-slide">
                            <div className="news-information-box-swiper-slide">
                                <div className="news-card-box">
                                    <div className="news-card">
                                        <img className="news-card-img" src={item.headerImg} />
                                        <div className="news-card-title">{item.title}</div>
                                        <div className="news-card-desc" dangerouslySetInnerHTML={{ __html: item.desc }}></div>
                                        <div className="news-card-btn" onClick={() => window.open(item.link)}>
                                            <span>查看详情</span>
                                            <i className="iconfont icontiaozhuanjiantou" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        )
    }
}