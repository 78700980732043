import React, { Component } from 'react';
import { ishaizhan } from '../../../../lib/utils/UtilsCommon';
import './index.less';

/**
 * 可选参数
 */
interface IProps {
	className?: string;
}

/**
 * 页面底部组件
 *
 * @class
 * @extends {React.Component<any>}
 */
class PageFooter extends Component<IProps> {
	/**
	 * 渲染页面
	 *
	 * @returns {*}
	 */
	public render(): any {
		const { className } = this.props;

		return (
			<div className={`page-footer-mobile ${className}`}>
				<div className="prds">
					<div className="prds-list">
						<div className="prds-list-item main-title">兴趣岛</div>
						<div className="prds-list-item-box">
							<div
								className="prds-list-item"
								onClick={(): any => {
									location.href = '/about';
								}}>
								公司简介
							</div>
							<div
								className="prds-list-item"
								onClick={(): any => {
									location.href = 'https://mp.weixin.qq.com/s?__biz=MzU3NDkwODMxMA==&mid=2247485827&idx=1&sn=e4fbff7ee9460b68a549a6908754a66d&chksm=fcbf62e2ff77214475c09bd2880f1c5ed38b8ae3ea9778cf822fce227c7e7b7be1f7a352e031&mpshare=1&scene=1&srcid=1112Rj0gwHzI7JJh4qV11J2r&sharer_shareinfo=7a56bca5fb2a374ef34f44f7f56715e8&sharer_shareinfo_first=7a56bca5fb2a374ef34f44f7f56715e8&from=industrynews&version=4.1.28.8017&platform=win&nwr_flag=1#wechat_redirect';
								}}>
								市场合作
							</div>
							<div
								className="prds-list-item"
								onClick={(): any => {
									location.href = 'https://www.liepin.com/company/8975743';
								}}>
								加入我们
							</div>
							<div
								className="prds-list-item"
								onClick={(): any => {
									location.href = '/sincerity-compliance';
								}}>
								诚信合规
							</div>
						</div>			
					</div>
					{/* <div className="prds-list">
						<div className="prds-list-item main-title">产品</div>
						<div
							className="prds-list-item"
							onClick={(): any => {
								location.href = 'https://pc.qlchat.com';
							}}>
							千聊知识工具
						</div>
					</div>
					<div className="prds-list">
						<div className="prds-list-item main-title">服务</div>
						<div
							className="prds-list-item"
							onClick={(): any => {
								location.href = 'https://mp.weixin.qq.com/mp/homepage?__biz=MzU4NjMyMDQwOA==&hid=10&sn=5eb52577a9902f0f6d6bb3b8574d1470&uin=&key=&devicetype=Windows+10&version=62060833&lang=zh_CN&ascene=1&winzoom=1.125&scene=1';
							}}>
							产品教程
						</div>
						<div
							className="prds-list-item"
							onClick={(): any => {
								location.href = 'https://mp.weixin.qq.com/s/B3wg0AjGvN1BUmiW_-bEfQ';
							}}>
							常见问题
						</div>
						<div
							className="prds-list-item"
							onClick={(): any => {
								location.href = 'https://m.qlchat.com/wechat/page/live/100000081018489?wcl=seo';
							}}>
							讲师学院
						</div>
						<div
							className="prds-list-item"
							onClick={(): any => {
								location.href = 'https://m.qlchat.com/video/admin/live/select?wcl=seo';
							}}>
							管理后台
						</div>
					</div>
					<div className="prds-list">
						<div className="prds-list-item main-title">关于兴趣岛</div>
						<div
							className="prds-list-item"
							onClick={(): any => {
								location.href = '/about';
							}}>
							公司简介
						</div>
						<div
							className="prds-list-item"
							onClick={(): any => {
								location.href = 'https://mp.weixin.qq.com/s/Otv56za4YkWP-2h31T_CJA';
							}}>
							市场合作
						</div>
						<div
							className="prds-list-item"
							onClick={(): any => {
								location.href = 'https://www.liepin.com/company/8975743';
							}}>
							加入我们
						</div>
						<div
							className="prds-list-item"
							onClick={(): any => {
								location.href = 'https://m.qlchat.com/privacy_policy.html';
							}}>
							隐私协议
						</div>
					</div> */}
				</div>
				<div className="contact-box pdrl32">
					<div className="title main-title">联系我们</div>
					<div className="text-font phone">电话：400-8328-080（工作日9:30-22:00）</div>
					<div className="text-font">地址：广州市天河区棠东东路25号兴趣岛总部</div>
					<div className="text-font">邮箱：jiamin.deng@qlchat.com</div>
					<div className="text-font">监察监督：jiancha@qlchat.com</div>
					<div className="code-list">
						<div className="code-list-item">
							<img src="https://img.qlchat.com/qlLive/activity/image/P6OQXGIO-VQIF-ZMAO-1709190133732-LNHWBFGXJBBU.png" />
							<div className="name">公众号</div>
						</div>
						<div className="code-list-item">
							<img src="https://img.qlchat.com/qlLive/activity/image/SQMING8S-D5NH-HW4P-1709190149252-529I3NJP3DBK.png" />
							<div className="name">APP下载</div>
						</div>
					</div>
				</div>
				<div className="other-ms pdrl32">
					<div className="ms-details">copyright C 2016-2022 {ishaizhan() ? 'www.haizhanweb.com' : 'www.qlchat.com'} inc. All Right Reserved {ishaizhan() ? '广州知识天下信息科技有限公司' : '广州思坞信息科技有限公司'} 版权所有</div>
					{
						ishaizhan() ?
						<div
							className="ms-details"
							onClick={(): any => {
								location.href = 'https://beian.miit.gov.cn/';
							}}>
							粤ICP备2021170501号
						</div>
						:
						<div
							className="ms-details"
							onClick={(): any => {
								location.href = 'https://beian.miit.gov.cn/';
							}}>
							粤ICP备15102474号-3
						</div>
					}
					<div
						className="ms-details"
						onClick={(): any => {
							location.href = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602005588';
						}}>
						粤公网安备 44010602002623号
					</div>
					<div
						className="ms-details"
						onClick={(): any => {
							location.href = 'http://img.qlchat.com/qlLive/liveCommon/wangwenzheng.jpg?x-oss-process=image/format,webp';
						}}>
						粤网文【2020】4310-268号
					</div>
					<div
						className="ms-details"
						onClick={(): any => {
							location.href = 'https://img.qlchat.com/qlLive/activity/image/2QUP81XW-SKBQ-WQDL-1567523544542-3DP73C2P65ON.jpg';
						}}>
						增值电信业务经营许可证：粤B2-20191058
					</div>
					{/* <div
						className="ms-details"
						onClick={(): any => {
							location.href = 'http://www.gdis.cn/';
						}}>
						互联网清理整顿
					</div> */}
				</div>
			</div>
		);
	}
}

export default PageFooter;
