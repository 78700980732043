import React, { Component } from 'react';
import { openApp } from '../../../../lib/utils/UtilsApp';
import './style.less';

/**
 * 可选参数
 */
interface IProps {
	audioParams?: any;
	activeTab?: string;
	parentName?: string;
	noFadeInUp?: boolean;
}

/**语音条 */
export default class TopMenu extends Component<IProps> {
	public state: any = {
		isShow: false,
		tabList: [
			{
				name: '首页',
				code: 'home',
				url: '/',
			},
			{
				name: '瑜伽学堂',
				code: 'yoga',
				url: '/qlchat-edu/category-module/75182625.html',
			},
			{
				name: '功法学堂',
				code: 'skills',
				url: '/qlchat-edu/category-module/393378225.html',
			},
			// {
			// 	name: '养生学堂',
			// 	code: 'cms',
			// 	url: '/qlchat-edu/category-module/99160584.html',
			// },
			{
				name: '摄影学堂',
				code: 'photography',
				url: '/qlchat-edu/category-module/24671985.html',
			},
			// {
			// 	name: '更多课程',
			// 	code: 'more',
			// 	url: '/qlchat-edu/more-courses',
			// },
			// {
			// 	name: '俱乐部广场',
			// 	code: 'community',
			// 	url: '/qlchat-edu/community',
			// },
			// {
			// 	name: '关于我们',
			// 	code: 'about',
			// 	url: '/about',
			// },
			// {
			// 	name: '加入我们',
			// 	code: 'join',
			// 	url: 'https://www.liepin.com/company/8975743',
			// },
		],
		isTransparent: true,
	};
	/**
	 * 加载
	 */
	public componentDidMount(): void {
		// this.initProgressBtn();
		this.listenScroll();
	}

	// 监听滚动条
	listenScroll(): void {
		const parentDom = document.querySelector(this.props.parentName);
		if(!parentDom) return;
		parentDom.addEventListener('scroll', (e) => {
			if(parentDom.scrollTop > 0) {
				this.setState({ isTransparent: false })
			} else {
				this.setState({ isTransparent: true })
			}
		})
	}

	/**渲染 */
	public render(): any {
		const { isShow, tabList, isTransparent } = this.state;
		const { activeTab = 'home', noFadeInUp } = this.props;

		return (
			<div className={`top-menu ${isShow ? 'show' : ''} ${isTransparent && !isShow ? 'transparent' : ''}`}>
				<div className="top-menu-header">
					<div className="logo-box">
						<img src="https://img.qlchat.com/qlLive/activity/image/1IKXNMJO-BHRV-5Z7E-1709101247557-PZBBF6ZCLQXK.png" />
						{/* <img src="https://img.qlchat.com/qlLive/activity/image/1HMDNQ1N-U1SL-SFBX-1717049926870-ZJIQXKN1KQGW.png" alt='兴趣岛66节' /> */}
					</div>
					<div className="menu-open-app">
						{/* <div className="open-app" onClick={(): any => {
							openApp();
						}}>打开APP</div> */}
						<div
							className={`collapse-button ${isShow ? 'show' : ''}`}
							onClick={(): void => {
								this.setState({
									isShow: !isShow,
								});
							}}>
							<span className="line-top"></span>
							<span className="line-middle"></span>
							<span className="line-bottom"></span>
						</div>
					</div>
				</div>
				<div className={`nav-list ${isShow ? 'show' : ''}`}>
					<div className="list-box">
						{tabList.map((item, index): any => {
							return (
								<div key={index} className={`list-item ${activeTab === item.code ? 'active' : ''}`} onClick={(): any => {
									location.href = item.url;
								}}>
									<span>{item.name}</span>
									<i className="iconfont-licai iconicon_more1"></i>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}
