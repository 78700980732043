import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.less';
import './Navigation.less';
import { playVideoDialog } from '../../../../../components/dialog-video-mobile';
import SwiperCore, { Autoplay, Pagination } from 'swiper/core';
SwiperCore.use([Pagination, Autoplay]);
import './index.less';

/**
 * 可选参数
 */
interface IProps {
	className?: string;
}

/**
 * 荣誉奖项
 *
 * @class HomeTrainingTopBanner
 * @extends {React.Component<any>}
 */
class HomeTrainingTopBanner extends Component<IProps> {

	public state: any = {
		banners: [
			{
				backgroundUrl: 'https://img.qlchat.com/qlLive/activity/image/TLJXA6DM-AWWU-ZUBA-1739253731590-67RIUYHT8KPX.png?x-oss-process=image/format,webp',
			},
			{
				backgroundUrl: 'https://img.qlchat.com/qlLive/activity/image/B8DCQ44K-MJS9-GG7H-1739253735676-6KR6MD8R88SK.png?x-oss-process=image/format,webp',
			},
			{
				backgroundUrl: 'https://img.qlchat.com/qlLive/activity/image/GPYHF9EH-9MIN-TRXS-1739253739317-WP5BET29RC92.png?x-oss-process=image/format,webp',
			},
		]
	};

	brandClick = () => {
		playVideoDialog({
			resourceURL: 'https://media.qlchat.com/qlLive/activity/video/U1R6AVKX-96CO-GBSM-1715337589389-K6NO63YD4P2V.mp4',
		});
	}
	/**
	 * 渲染页面
	 *
	 * @returns {*}
	 */
	public render(): any {
		const { banners } = this.state;
		const bannersFlag: boolean = banners.length > 1;

		return (
			<div className="home-training-top-banner-mobile" id="home-training-top-banner-mobile">
				<Swiper
					observer={true}
					observeParents={true}
					loop={bannersFlag}
					autoplay={{
						delay: 5000,
						disableOnInteraction: false,
					}}
					// pagination={{ clickable: true }}
					className="home-training-top-banne-swiper">
					{banners.map((item: any, index: number): any => (
						<SwiperSlide key={index}>
							<div
								className="home-training-top-banne-swiper-img"
								onClick={(): void => {
									if (item.linkUrl) {
										window.open(item.linkUrl);
									}
								}}>
								<img src={item.backgroundUrl}/>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
				{/* <div className="home-training-top-banner-mobile-brand">
					<div className="brand-left">
						<div className="brand-title">兴趣岛</div>
						<div className="brand-text">国内领先的兴趣学习品牌</div>
					</div>
					<div className="brand-right" onClick={() => this.brandClick()}></div>
				</div> */}
			</div>
		);
	}
}

export default HomeTrainingTopBanner;
