import React, { PureComponent } from 'react';
import './LoadingStyle.less';

/** Loading props */
interface IProps {
	show: boolean;
	text: string;
	showIcon?: boolean;
	[index: string]: any;
}
/**
 * index page
 *
 * @class Loading
 * @extends {React.Component<IProps,any>}
 */
class Loading extends PureComponent<IProps, any> {
	constructor(props: IProps) {
		super(props);
	}

	/**
	 * render
	 *
	 * @returns {*}
	 * @memberof Loading
	 */
	public render(): any {
		const { show, text, showIcon = false } = this.props;

		return (
			<div className={`loading-container ${show && 'shown-loading'}`}>
				<div className="loading-content">
					{showIcon ? (
						<div>
							<div className="loading-fish-box">
								<div className="sprite-fish loading-fish-bg"></div>
								<div className="sprite-fish loading-fish-img"></div>
								<div className="sprite-fish loading-fish-shadow"></div>
							</div>
							{text && <p className="loading-text loading-prompt">{text}</p>}
						</div>
					) : (
						<div className="loading-tip">
							<div className="loading-prompt">{text}</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default Loading;
