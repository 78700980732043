import React, { PureComponent, useState, useEffect } from 'react';
import Head from 'next/head';
// import Detect from '../../utils/detect';
import Toast from '../toast/ToastIndex';
import Loading from '../loading/LoadingIndex';
// import { reConfigWx } from '../../utils/wx-util'

// /* 引入通用样式 */
import '../../lib/assets/styles/StyleMobile.less';
import '../../lib/assets/styles/StyleReset.less';
import '../../lib/assets/styles/StyleFlex.less';
// import '../../lib/assets/styles/StyleForm.less';

import './PageStyle.less';
import { isPc } from '../../lib/utils/UtilsEnvi';

/** Page props */
interface IPageProps {
	title?: string;
	bodyClass?: string;
	isroi?: string;

	[index: string]: any;

	resetClass?: string;
}

const Page: any = ({ bodyClass, title, pageDescription, pageKeyword, children, resetClass = '' }: IPageProps): any => {
	const [pageTitle, setPageTitle]: any = useState(title);
	const [showToast, setShowToast]: any = useState(null);
	const [showLoading, setShowLoading]: any = useState(null);
	const pc: boolean = isPc();

	const toastAction: any = (text: string, during?: number, bigFont?: boolean): any => {
		setShowToast({
			toastShow: true,
			toastText: text,
			bigFont,
		});
		setTimeout((): any => {
			setShowToast({
				toastShow: false,
				toastText: text,
				bigFont,
			});
		}, during || 1000);
	};
	const loadingAction: any = (show: boolean, text: string, showIcon?: boolean): any => {
		setShowLoading({
			loadingShow: show,
			loadingText: text,
			loadingShowIcon: showIcon,
		});
	};
	useEffect((): void => {
		window.toast = toastAction;
		window.loading = loadingAction;
	}, []);

	useEffect((): void => {
		setPageTitle(title);
	}, [title]);

	// didmount
	useEffect((): void => {
		setTimeout((): void => {
			if (typeof _qla !== 'undefined') {
				_qla('pv', {});
				_qla.bindVisibleScroll('scroll-content-container');
				_qla.bindVisibleScroll('co-scroll-to-load');
				_qla.bindVisibleScroll('app-warp ');
			}
		}, 1500);
	}, []);

	const pageClassName: any = (): any => {
		return `app-warp ${resetClass}`;
	};

	return (
		<React.Fragment>
			<div className={pageClassName()}>
				<Head>
					<title>{pageTitle}</title>
					<meta name="description" content={pageDescription} />
					<meta name="keywords" content={pageKeyword || pageTitle} />
					<meta property="og:title" content={pageTitle} />
					<meta property="og:description" content={pageDescription} />
				</Head>
				<div className={`${bodyClass}`}>{children}</div>
			</div>
			{showToast && <Toast isOpen={showToast.toastShow} content={showToast.toastText} bigFont={showToast.bigFont} />}
			{showLoading && <Loading show={showLoading.loadingShow} text={showLoading.loadingText} showIcon={showLoading.loadingShowIcon} />}
		</React.Fragment>
	);
};

export default Page;
